.main-content {
  max-width: 100vw;
}

.print_only_show {
  display: none;
}

.total_weight {
  font-size: 16px;
  font-weight: 500;
}



td.thick_2_00 {
  background-color: #fc00ff52;
}

td.thick_1_00 {
  background-color: #ffef0045;
}

td.thick_1_50 {
  background-color: #00c8ff70;
}

td.thick_1_20 {
  background-color: #42ff0054;
}

td.width_1220_00 {
  background-color: #f14c4c45;
}

td.width_1225_00 {
  background-color: #005cff2e;
}

td.width_1250_00 {
  background-color: #928f002e;
}

td.width_1500_00 {
  background-color: #7fffd48f;
}

td.width_900_00 {
  background-color: #e22bb457;
}

.is_deleted_True {
  background-color: #a1000054;
}

.stock_low {
  background-color: #ff00005e !important;

}

.w-33 {
  width: 33.33333%;
}

.workorder-form input,
.workorder-form select,
.workorder-form textarea {
  /* font-size: 14px;
  font-weight: bold; */
  width: 100%;
  padding: 5px;
  border: 1px #d5d5d5 solid;


}

.workorder-form textarea {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #ffffff00;
  border: none;

}

.workorder-form p {
  white-space: pre-wrap;
}

.workorder-form textarea::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Edge */
}

.workorder-form .left-box {
  width: 470px;
}

.workorder-form .left-box td,
.workorder-form .left-box th {
  padding: 0 3px;
  vertical-align: middle;
}

.workorder-form .left-box th {
  /* width: 120px; */
}

.workorder-form .left-box th div {
  /* width: 200px; */
}

.workorder-form .left-box th div input {
  width: unset;
  border: none;
}

.order-number p {
  font-size: 14px;
  font-weight: bold;
}

.order-number input {
  font-size: 14px;
  font-weight: bold;
  width: 160px;
  padding: 5px;
}

.image-container {
  width: 200px;
  position: relative;
}

.image-container img {
  width: 100%;
}

.image-container span {
  top: -3px;
  right: -3px;
  background-color: #e90000;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  width: 19px;
  height: 19px;
  text-align: center;
}

.workorder-form .row-box th {
  font-size: 13px;
  padding: 6px 3px;
}

.workorderitem td {
  padding: 0;
  height: 100%;
  vertical-align: middle;
}

.workorderitem input,
.workorderitem select {
  padding: 2 0;
  border: 0;
  background-color: #ffffff00;
  text-align: center;
}
table.order_item_table th input{
  border: 0;
  background-color: #ffffff00;
  font-weight: 600;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));

}


table.work-order-details-table th,
table.work-order-details-table td {
  border: 2px solid #c5c5c573;
  background-color: rgb(230, 250, 249);
}

table.work-order-details-table tr :first-child {
  font-weight: 600;
  background-color: rgb(219, 236, 236);

}


.production-item-table {
  border: 2px solid #adb8ff8f
}


.work-order-item-table {
  margin-bottom: 12px;
}

table.work-order-item-table th {
  border: 2px solid #4d02028f;
  background-color: rgb(210, 161, 212);
}

table.work-order-item-table td {
  border: 2px solid #4d02028f;
  background-color: rgb(230, 201, 231);
}

table.production-item-table th {
  border: 2px solid #0001308f;
  background-color: rgb(161, 212, 197);
}

table.production-item-table td {
  border: 2px solid #0001308f;
  background-color: rgb(193, 218, 210);
}


.work-order-item-table-parent {
  border: 2px solid #ffadad8f;
  margin-bottom: 12px;
}

/* table.production-item-work-table{
  border: 2px solid #0000008f;
} */
table.production-item-work-table th {
  background-color: rgb(214, 195, 195);
  border: 2px solid #0000008f;

}

table.production-item-work-table td {
  background-color: rgb(255, 235, 235);
  border: 2px solid #9797978f;

}

.work-order-images {
  align-items: start;
}

.work-order-images img {
  object-fit: contain;
  min-width: 25%;
  margin-right: 5px;
  vertical-align: top;
}

.workorder-form table,
.workorder-form table th,
.workorder-form table td,
.workorder-form table tr {
  border: none !important;
}

.address_table.table,
.address_table.table th,
.address_table.table td,
.address_table.table tr {
  border: 1px solid #0000000f !important;
}

.workorder-form .order_item_table.table,
.workorder-form .order_item_table.table th,
.workorder-form .order_item_table.table td,
.workorder-form .order_item_table.table tr {
  border: 1px solid #0000000f !important;
}

.workorder-form .order_item_table {
  margin-top: 20px;
}

.loha_logo {
  height: 50px;
}

.iso_Badge {
  height: 80px;
}
.disabled_column {
  background-color: #00000078 !important;
}


.order_print_view {
  font-size: 10px !important;
}
.order_print_view .workorder-form .row-box th {
  font-size: 10px !important;
}


.order_print_view .loha_logo {
  height: 30px;
}

.order_print_view .iso_Badge {
  height: 55px;
}

.order_print_view .workorder-form .left-box td, .order_print_view .workorder-form .left-box th{
  padding: 3px !important;
}

.order_print_view .workorder-form input {
  padding: 0;
  border: none;
  background: none;
  color: #000;
}



.order_print_view .order-number input {
  border: none;
}

.order_print_view .address_table.table,
.order_print_view .address_table.table th,
.order_print_view .address_table.table td,
.order_print_view .address_table.table tr {
  border: none !important;
}
.order_print_view td{
  color: #000;
}


.order_print_view .work_order_image_input {
  display: none;
}

.order_print_view .disabled_column {
  display: none !important;
}
.order_print_view .workorder-form p{
  margin: 4px 0;
}
.order_print_view .print_hide{
  display: none;
}

.workorder-form .notes{
  white-space: pre-wrap;
  font-weight:600;
}

.workorder-form .total-quantity{
  font-weight: 600;
}
.workorder-form .total-quantity .left-td {
  text-align: right;
}

/* print  */

@media print {

  .print_bg_white,
  .print_bg_white table,
  .print_bg_white table th,
  .print_bg_white table td {
    background-color: rgb(255, 255, 255);
  }

  body {
    background-color: rgb(255, 255, 255);
  }

  select {
    appearance: none;
    /* Remove default styling */
    -webkit-appearance: none;
    /* For Safari */
    -moz-appearance: none;
    /* For Firefox */
    border: 1px solid #ccc;
    /* Optional: Add your own border */
    background-color: #fff;
    /* Optional: Set your own background color */
    padding: 8px;
    /* Optional: Add some padding */
    /* Optional: Add more styling as needed */
  }

  .print_hide {
    display: none !important;
  }

  .print_only_show {
    display: block;
  }

  .workorder-form input,
  .workorder-form textarea {
    padding: 3px;
    border: none;
    background-color: #ffffff00;

  }

  .workorder-form .css-yk16xz-control {
    border: none;
  }

  .workorder-form .table-bordered th,
  .workorder-form .table-bordered td {
    border: 1px solid #550000;
  }

  .workorder-form .left-box th {
    font-size: 10px;
  }

  .workorder-form .row-box table {
    /* display: table-caption; */
  }

  .workorder-form .row-box th {
    font-size: 10px;
    padding: 6px 0px;
  }

  .workorder-form .row-box td {
    font-size: 10px;
    padding: 0;
  }

  .print_scroll_hide {
    overflow: hidden !important;
  }
  .disabled_column {
    display: none !important;
  }

}

/* .workorder-form .left-box th{
  font-size: 11px;
}
.workorder-form .row-box table{
  display: table-caption;
}
.workorder-form .row-box th{
  font-size: 10px;
  padding: 0;
}
.workorder-form .row-box td{
  font-size: 10px;
  padding: 0;
} */